<template>
  <div class="row justify-content-center shadow-right p-3 bg-white h-100">
    <div class="col-md-11 px-0" style="text-align: left">
      <!-- dashboard -->
      <span class="d-flex pl-3 py-2 font-weight-bold typo-blue">Dashboard</span>

      <span class="d-flex pl-3 def-py-2 my-1 text-wrap" :class="activeMenu(v.route)" v-for="(v, i) in menu.parent"
        :key="i" @click="pushTo(v.route)">
        <span class="material-symbols-outlined mr-2">
          {{ v.icon }}
        </span>
        {{ v.name }}
      </span>

      <div v-for="(v, i) in menu.transactions" :key="i">
        <span class="d-flex pl-3 py-2 font-weight-bold typo-blue">{{
          v.parent
        }}</span>

        <span class="d-flex pl-3 def-py-2 my-1 text-wrap" :class="activeMenu(value.route)" v-for="(value, id) in v.sub"
          :key="id" @click="pushTo(value.route)">
          <span class="material-symbols-outlined mr-2">
            {{ value.icon }}
          </span>
          {{ value.name }}
        </span>
      </div>

      <!-- master data -->
      <span class="d-flex pl-3 py-2 font-weight-bold typo-blue">Master Data</span>

      <span class="d-flex pl-3 def-py-2 my-1 text-wrap" :class="activeMenu(v.route)" v-for="(v, i) in menu.master"
        :key="i" @click="pushTo(v.route)">
        <span class="material-symbols-outlined mr-2">
          {{ v.icon }}
        </span>
        {{ v.name }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarComponent",

  props: {
    activePage: String,
  },

  data() {
    return {
      classActive: "def-side-items",
      menu: {
        parent: [
          {
            name: "Dashboard",
            icon: "dashboard",
            route: "dashboard",
          },
        ],
        transactions: [
          {
            parent: "Lowongan & Pelamar",
            sub: [
              {
                name: "Lowongan",
                icon: "work",
                route: "job-vacancies-list",
              },
              {
                name: "Iklan",
                icon: "campaign",
                route: "advertisements-list",
              },
              {
                name: "Perusahaan",
                icon: "meeting_room",
                route: "companies-list",
              },
              {
                name: "Alumni",
                icon: "school",
                route: "alumnuses-list",
              },
              {
                name: "Laporan Rekrutmen",
                icon: "description",
                route: "accepted-candidates-list",
              },
            ],
          },
        ],
        master: [
          {
            name: "Kompetensi Keahlian",
            icon: "construction",
            route: "sub-majorities-list",
          },
          {
            name: "Program Keahlian",
            icon: "construction",
            route: "majorities-list",
          },
          {
            name: "Keahlian",
            icon: "construction",
            route: "expertises-list",
          },
          {
            name: "Pengguna",
            icon: "group",
            route: "users-list",
          },
          {
            name: "Tipe Pengalaman",
            icon: "workspaces",
            route: "experience-types-list",
          },
          {
            name: "Tipe Pekerjaan",
            icon: "workspaces",
            route: "job-types-list",
          },
          {
            name: "Level Peran",
            icon: "workspaces",
            route: "roles-list",
          },
        ],
      },
    };
  },
  created() { },
  methods: {
    pushTo(router) {
      const currentRoute = this.$route.name;

      const targetRoute = router;

      if (currentRoute !== targetRoute) {
        this.$router.push({ name: targetRoute });
      }
    },
    activeMenu(router) {
      const currentRoute = this.$route.name;
      
      const targetRoute = router;

      if (currentRoute === targetRoute) {
        return "def-side-items-active";
      } else {
        return "def-side-items";
      }
    },
  },
  mounted() { },
};
</script>
