const ID_TOKEN_KEY = "id_token";
const ID_TOKEN_EXP_KEY = "exp_in";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getExp = () => {
  return window.localStorage.getItem(ID_TOKEN_EXP_KEY);
};

export const saveToken = (token, exp) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
  window.localStorage.setItem(ID_TOKEN_EXP_KEY, exp);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.removeItem("access_right");
  window.localStorage.removeItem("user");
};

export const saveRefreshToken = (RefreshToken) => {
  window.localStorage.setItem("RefreshToken", RefreshToken);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem("RefreshToken");
};

export const destroyRefreshToken = () => {
  window.localStorage.removeItem("RefreshToken");
};

export const isTokenValid = () => {
  let token = localStorage.getItem(ID_TOKEN_KEY);
  let exp = localStorage.getItem(ID_TOKEN_EXP_KEY);
  if (token) {
    return exp * 1000 > Date.now();
  }
  return false;
};

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem("user"));
};

export const setUser = (user) => {
  window.localStorage.setItem("user", user);
};

export const destroyUser = () => {
  window.localStorage.removeItem("user");
};

export default {
  getToken,
  saveToken,
  saveRefreshToken,
  getRefreshToken,
  destroyToken,
  destroyRefreshToken,
  getUser,
  setUser,
  destroyUser,
  isTokenValid,
  getExp,
};
