<template>
    <div>
        <!-- navbar -->
        <NavbarAdminComponent></NavbarAdminComponent>

        <!-- body -->
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col-md-2 h-100">
                    <!-- sidebar -->
                    <SidebarAdminComponent activePage="majorities"></SidebarAdminComponent>
                </div>
                <div class="col-md-10 pt-3 text-left">
                    <!-- child page -->
                    <router-view></router-view>
                </div>

            </div>
        </div>

        <!-- footer -->
        <FooterComponent></FooterComponent>

    </div>
</template>

<script>
import FooterComponent from '@/views/components/admin/FooterAdminComponent.vue';
import NavbarAdminComponent from '@/views/components/admin/NavbarAdminComponent.vue';
import SidebarAdminComponent from '@/views/components/admin/SidebarAdminComponent.vue';

export default {
    components: {
        NavbarAdminComponent,
        SidebarAdminComponent,
        FooterComponent
    },

};
</script>
