<template>
    <div>
        <!-- navbar -->
        <NavbarComponent></NavbarComponent>

        <!-- body -->
        <div class="container-fluid mb-5 content-section">
            <div class="row">
                <div class="col-md-12 px-0">
                    <!-- child page -->
                    <router-view></router-view>
                </div>

            </div>
        </div>

        <!-- footer -->
        <FooterComponent></FooterComponent>

    </div>
</template>

<script>
import FooterComponent from '@/views/components/client/FooterComponent.vue';
import NavbarComponent from '@/views/components/client/NavbarComponent.vue';


export default {
    components: {
        NavbarComponent,
        FooterComponent
    },

};
</script>

<style scoped></style>
