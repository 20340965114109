import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";
import router from "@/router";


/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    // axios.defaults.baseURL = "http://localhost:8000/api";
    axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
  },

  setHeader() {
    // Set authorization header
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  setHeaderLogin(token) {
    // Set authorization header
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  query(resource, params) {
    this.setHeader()
    return axios.get(resource, params).catch((error) => {
      if (error.response.status == 401) {
        JwtService.destroyToken();
        router.push({ name: "login" });
      }
      return error.response;
    });
  },

  get(resource, slug = "") {
    this.setHeader()
    // Use axios.get with proper URL formatting
    return axios.get(`${resource}${slug}`).catch((error) => {
      if (error.response.status == 401) {
        JwtService.destroyToken();
        router.push({ name: "login" });
      }

      return error.response;
    });
  },

  post(resource, params) {
    this.setHeader()
    // Use axios.post without string interpolation
    return axios.post(resource, params);
  },

  update(resource, slug, params) {
    this.setHeader()
    // Use axios.put with proper URL formatting
    return axios.put(`${resource}${slug}`, params);
  },

  put(resource, params) {
    this.setHeader()
    // Use axios.post with proper URL formatting and query parameter for PUT method override
    return axios.post(`${resource}?_method=PUT`, params);
  },

  delete(resource) {
    this.setHeader()
    // Use axios.delete for DELETE requests
    return axios.delete(resource).catch((error) => {
      if (error.response.status == 401) {
        JwtService.destroyToken();
        router.push({ name: "login" });
      }
      return error.response;
    });
  },
};

export default ApiService;
