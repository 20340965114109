<template>
    <div>
        <b-navbar variant="faded" type="light" class="def-navbar shadow-bottom">
            <div class="container-fluid">
                <b-navbar-brand href="#">
                    <h3 class="nav-ml-90 mb-0 d-none d-md-none d-lg-block" style="color: #203D7F; font-weight: 600;">
                        CONNECT-IN
                    </h3>
                    <h5 class="mb-0 d-block d-md-block d-lg-none" style="color: #203D7F; font-weight: 600;">
                        CONNECT-IN
                    </h5>
                </b-navbar-brand>
                <b-navbar-nav>
                    <b-nav-text class="d-flex">
                        <span class="d-none text-dark d-sm-none d-md-none d-lg-flex align-items-center mr-2 ml-1">
                            Hi, {{ user.name }}</span>
                    </b-nav-text>

                    <!-- old -->
                    <b-nav-item-dropdown right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <b-avatar variant="warning" size="md">
                                <span class="material-symbols-outlined">person</span>
                            </b-avatar>
                        </template>

                        <b-dropdown-text class="d-block d-sm-block d-md-block d-lg-none ">
                            <span class="font-weight-bold d-block">{{ user.name }}</span>
                        </b-dropdown-text>
                        <b-dropdown-divider class="d-block d-sm-block d-md-block d-lg-none"></b-dropdown-divider>
                        <b-dropdown-item>Akun</b-dropdown-item>
                        <b-dropdown-item @click="signOut">
                            <span class="text-danger">Keluar</span>
                        </b-dropdown-item>
                    </b-nav-item-dropdown>

                </b-navbar-nav>
            </div>
        </b-navbar>
    </div>
</template>

<script>
import jwtService from '@/services/jwt.service';
import Swal from 'sweetalert2';

export default {
    name: 'NavbarComponent',
    data() {
        return {
            user: {}
        };
    },
    methods: {
        getCurrentUser() {
            this.user = jwtService.getUser()
        },

        signOut() {
            Swal.fire({
                title: 'Apakah Anda yakin?',
                text: "Anda akan keluar dari sesi ini.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#203d7f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, keluar!',
                cancelButtonText: 'Batal'
            }).then((result) => {
                if (result.isConfirmed) {
                    jwtService.destroyRefreshToken();
                    jwtService.destroyToken();
                    jwtService.destroyUser();

                    Swal.fire({
                        title: 'Logout Berhasil!',
                        text: 'Anda telah berhasil keluar.',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });

                    this.$router.push("/login");
                }
            });
        }

    },
    mounted() {
        this.getCurrentUser();

    }
};
</script>

<style scoped>
/* .nav-ml-90 {
    margin-left: 100px !important;
}

.nav-mr-90 {
    margin-right: 100px !important;
} */

.def-navbar {
    padding-left: 5% !important;
    padding-right: 5% !important;

    top: 0;
    width: 100%;
    /* Ensure the navbar spans the full width of the screen */
    z-index: 1000;
    /* High z-index to ensure it's in front of other elements */
    background-color: white;
    /* Background color to avoid overlap issues */
}

.d-inline {
    display: inline !important;
}
</style>
