import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ApiService from "@/services/AxiosService";
// import store from "@/modules/store"; // Import the Vuex store

// Import Modules
// import "@/assets/css/style.css";
import "@/assets/scss/main.scss";
import "@/core/Bootstrap-vue";
import Swal from "sweetalert2";
import authMiddleware from "./modules/authMiddleware";
import vSelect from "vue-select";
import VueExcelXlsx from "vue-excel-xlsx";

Vue.component("v-select", vSelect);
Vue.use(VueExcelXlsx);

ApiService.init();

// SweetAlert
window.Swal = Swal;
window.SwalLoading = Swal.mixin({
  title: "Loading",
  text: "Sedang memproses data",
  icon: "info",
  allowOutsideClick: false,
  allowEscapeKey: false,
  showConfirmButton: false,
  heightAuto: false,
  willOpen: () => {
    Swal.showLoading();
  },
});

Vue.config.productionTip = false;
Vue.prototype.$swal = Swal;

new Vue({
  router,
  // store,
  render: (h) => h(App),
}).$mount("#app");

router.beforeEach(authMiddleware);
