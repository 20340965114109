import LayoutComponents from "@/views/layouts/LayoutComponents.vue";
import ClientLayoutComponents from "@/views/layouts/ClientLayoutComponents.vue";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

var appName = process.env.VUE_APP_NAME;

const routes = [
	{
		path: "/login",
		name: "login",
		meta: {
			title: appName + " | Login",
		},
		component: () => import("@/views/pages/auth/LoginComponent.vue"),
	},
	{
		path: "/",
		name: "home",
		meta: {
			title: appName + " | Home",
		},
		component: ClientLayoutComponents,
		children: [
			{
				path: "/",
				name: "home",
				component: () => import("@/views/pages/client/HomeClient.vue"),
				meta: {
					title: `Home`,
					access_right: "",
				},
			},
			{
				path: "/job-vacancies/detail/:id",
				name: "Detail Lowongan",
				component: () => import("@/views/pages/client/alumnuses/job-vacancies/DetailJobVacancy.vue"),
				meta: {
					title: `Detail Lowongan`,
					access_right: "",
				},
			},
		],
	},

	{
		path: "/client/job-vacancies",
		name: "job-vacancies",
		meta: {
			title: appName + " | Lowongan Pekerjaan",
		},
		component: ClientLayoutComponents,
		children: [
			{
				path: "/client/job-vacancies/detail/:id",
				name: "Detail Lowongan",
				component: () => import("@/views/pages/client/alumnuses/job-vacancies/DetailJobVacancy.vue"),
				meta: {
					title: `Detail Lowongan`,
					access_right: "",
				},
			},
			{
				path: "/client/job-vacancies/company/detail/:id",
				name: "Detail Lowongan",
				component: () => import("@/views/pages/client/companies/job-vacancies/DetailJobVacancy.vue"),
				meta: {
					title: `Detail Lowongan`,
					access_right: "",
				},
			},
			{
				path: "/client/job-vacancies/company/add",
				name: "Detail Lowongan",
				component: () => import("@/views/pages/client/companies/job-vacancies/AddJobVacancy.vue"),
				meta: {
					title: `Tambah Lowongan`,
					access_right: "",
				},
			},
			{
				path: "/client/job-vacancies/company/edit/:id",
				name: "Detail Lowongan",
				component: () => import("@/views/pages/client/companies/job-vacancies/EditJobVacancy.vue"),
				meta: {
					title: `Detail Lowongan`,
					access_right: "",
				},
			},
			{
				path: "/client/job-vacancies/company/profile-applicant/:id",
				name: "Profil Pelamar",
				component: () => import("@/views/pages/client/companies/job-vacancies/ProfileApplicant.vue"),
				meta: {
					title: `Profil Pelamar`,
					access_right: "",
				},
			},
		],
	},

	{
		path: "/profile",
		name: "profile",
		meta: {
			title: appName + " | Profile",
		},
		component: ClientLayoutComponents,
		children: [
			{
				path: "/profile",
				name: "Profile",
				component: () => import("@/views/pages/client/ProfileClient.vue"),
				meta: {
					title: `Profile`,
					access_right: "",
				},
			},
		],
	},
	
	{
		path: "/applied-jobs",
		name: "applied-jobs",
		meta: {
			title: appName + " | Daftar Lamaran",
		},
		component: ClientLayoutComponents,
		children: [
			{
				path: "/applied-jobs",
				name: "Daftar Lamaran",
				component: () => import("@/views/pages/client/alumnuses/AppliedJob.vue"),
				meta: {
					title: `Daftar Lamaran`,
					access_right: "",
				},
			},
		],
	},

	// ====================== ADMIN ROUTERS ==============================
	{
		path: "/dashboard",
		meta: {
			title: appName + " | Dashboard",
		},
		component: LayoutComponents,
		children: [
			{
				path: "/dashboard",
				name: "dashboard",
				component: () => import("@/views/pages/admin/HomeAdmin.vue"),
				meta: {
					title: `Dashboard`,
					access_right: "",
				},
			},
		],
	},

	// User
	{
		path: "/users",
		component: LayoutComponents,
		meta: {
			title: `Master Pengguna`,
			access_right: "",
		},
		children: [
			{
				path: "/users/list",
				name: "users-list",
				component: () => import("@/views/pages/admin/users/ListUsers.vue"),
				meta: {
					group: "users",
					title: `Daftar Pengguna`,
					access_right: "",
				},
			},
			{
				path: "/users/add",
				name: "users-add",
				component: () => import("@/views/pages/admin/users/AddUsers.vue"),
				meta: {
					title: `Tambah Pengguna`,
					access_right: "",
				},
			},
			
			{
				path: "/users/detail/:id",
				name: "users-detail",
				component: () => import("@/views/pages/admin/users/DetailUsers.vue"),
				meta: {
					title: `Detail Pengguna`,
					access_right: "",
				},
			},
		],
	},
	// Roles
	{
		path: "/roles",
		component: LayoutComponents,
		meta: {
			title: `Master Posisi`,
			access_right: "",
		},
		children: [
			{
				path: "/roles/list",
				name: "roles-list",
				component: () => import("@/views/pages/admin/roles/ListRoles.vue"),
				meta: {
					group: "roles",
					title: `Daftar Posisi`,
					access_right: "",
				},
			},
		],
	},
	// advertisements
	{
		path: "/advertisements",
		component: LayoutComponents,
		meta: {
			title: `Master Program Keahlian`,
			access_right: "",
		},
		children: [
			{
				path: "/advertisements/list",
				name: "advertisements-list",
				component: () => import("@/views/pages/admin/advertisements/ListAdvertisements.vue"),
				meta: {
					group: "advertisements",
					title: `Daftar Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/advertisements/add",
				name: "advertisements-add",
				component: () => import("@/views/pages/admin/advertisements/AddAdvertisements.vue"),
				meta: {
					title: `Tambah Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/advertisements/edit/:id",
				name: "advertisements-edit",
				component: () => import("@/views/pages/admin/advertisements/EditAdvertisements.vue"),
				meta: {
					title: `Ubah Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/admin/advertisements/detail/:id",
				name: "advertisements-detail",
				component: () => import("@/views/pages/admin/advertisements/DetailAdvertisements.vue"),
				meta: {
					title: `Detail Program Keahlian`,
					access_right: "",
				},
			},
		],
	},
	// job-vacancies
	{
		path: "/job-vacancies",
		component: LayoutComponents,
		meta: {
			title: `Master Program Keahlian`,
			access_right: "",
		},
		children: [
			{
				path: "/job-vacancies/list",
				name: "job-vacancies-list",
				component: () => import("@/views/pages/admin/job-vacancies/ListJobVacancies.vue"),
				meta: {
					group: "job-vacancies",
					title: `Daftar Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/job-vacancies/add",
				name: "job-vacancies-add",
				component: () => import("@/views/pages/admin/job-vacancies/AddJobVacancies.vue"),
				meta: {
					title: `Tambah Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/job-vacancies/edit/:id",
				name: "job-vacancies-edit",
				component: () => import("@/views/pages/admin/job-vacancies/EditJobVacancies.vue"),
				meta: {
					title: `Ubah Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/admin/job-vacancies/detail/:id",
				name: "job-vacancies-detail",
				component: () => import("@/views/pages/admin/job-vacancies/DetailJobVacancies.vue"),
				meta: {
					title: `Detail Program Keahlian`,
					access_right: "",
				},
			},
		],
	},
	{
		path: "/majorities",
		component: LayoutComponents,
		meta: {
			title: `Master Program Keahlian`,
			access_right: "",
		},
		children: [
			{
				path: "/majorities/list",
				name: "majorities-list",
				component: () => import("@/views/pages/admin/majorities/ListMajorities.vue"),
				meta: {
					group: "majorities",
					title: `Daftar Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/majorities/add",
				name: "majorities-add",
				component: () => import("@/views/pages/admin/majorities/AddMajorities.vue"),
				meta: {
					title: `Tambah Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/majorities/edit/:id",
				name: "majorities-edit",
				component: () => import("@/views/pages/admin/majorities/EditMajorities.vue"),
				meta: {
					title: `Ubah Program Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/majorities/detail/:id",
				name: "majorities-detail",
				component: () => import("@/views/pages/admin/majorities/DetailMajorities.vue"),
				meta: {
					title: `Detail Program Keahlian`,
					access_right: "",
				},
			},
		],
	},
	// Sub Majorities
	{
		path: "/sub-majorities",
		component: LayoutComponents,
		meta: {
			title: `Master Kompetensi Keahlian`,
			access_right: "",
		},
		children: [
			{
				path: "/sub-majorities/list",
				name: "sub-majorities-list",
				component: () => import("@/views/pages/admin/sub-majorities/ListSubMajorities.vue"),
				meta: {
					group: "sub-majorities",
					title: `Daftar Kompetensi Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/sub-majorities/add",
				name: "sub-majorities-add",
				component: () => import("@/views/pages/admin/sub-majorities/AddSubMajorities.vue"),
				meta: {
					title: `Tambah Kompetensi Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/sub-majorities/edit/:id",
				name: "sub-majorities-edit",
				component: () => import("@/views/pages/admin/sub-majorities/EditSubMajorities.vue"),
				meta: {
					title: `Ubah Kompetensi Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/sub-majorities/detail/:id",
				name: "sub-majorities-detail",
				component: () => import("@/views/pages/admin/sub-majorities/DetailSubMajorities.vue"),
				meta: {
					title: `Detail Kompetensi Keahlian`,
					access_right: "",
				},
			},
		],
	},
	// Experience Type
	{
		path: "/experience-types",
		component: LayoutComponents,
		meta: {
			title: `Master Pengalaman`,
			access_right: "",
		},
		children: [
			{
				path: "/experience-types/list",
				name: "experience-types-list",
				component: () => import("@/views/pages/admin/experience-types/ListExperienceTypes.vue"),
				meta: {
					group: "experience-types",
					title: `Daftar Posisi`,
					access_right: "",
				},
			},
		],
	},
	// Job Type
	{
		path: "/accepted-candidates",
		component: LayoutComponents,
		meta: {
			title: `Pelamar Diterima`,
			access_right: "",
		},
		children: [
			{
				path: "/accepted-candidates/list",
				name: "accepted-candidates-list",
				component: () => import("@/views/pages/admin/accepted-candidates/ListAcceptedCandidates.vue"),
				meta: {
					group: "accepted-candidates",
					title: `Daftar Posisi`,
					access_right: "",
				},
			},
		],
	},
	// Job Type
	{
		path: "/job-types",
		component: LayoutComponents,
		meta: {
			title: `Master Pengalaman`,
			access_right: "",
		},
		children: [
			{
				path: "/job-types/list",
				name: "job-types-list",
				component: () => import("@/views/pages/admin/job-types/ListJobTypes.vue"),
				meta: {
					group: "job-types",
					title: `Daftar Posisi`,
					access_right: "",
				},
			},
		],
	},
	// Expertises
	{
		path: "/expertises",
		component: LayoutComponents,
		meta: {
			title: `Master Program Keahlian`,
			access_right: "",
		},
		children: [
			{
				path: "/expertises/list",
				name: "expertises-list",
				component: () => import("@/views/pages/admin/expertises/ListExpertises.vue"),
				meta: {
					group: "expertises",
					title: `Daftar Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/expertises/add",
				name: "expertises-add",
				component: () => import("@/views/pages/admin/expertises/AddExpertises.vue"),
				meta: {
					title: `Tambah Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/expertises/edit/:id",
				name: "expertises-edit",
				component: () => import("@/views/pages/admin/expertises/EditExpertises.vue"),
				meta: {
					title: `Ubah Keahlian`,
					access_right: "",
				},
			},
			{
				path: "/expertises/detail/:id",
				name: "expertises-detail",
				component: () => import("@/views/pages/admin/expertises/DetailExpertises.vue"),
				meta: {
					title: `Detail Keahlian`,
					access_right: "",
				},
			},
		],
	},
	// companies
	{
		path: "/companies",
		component: LayoutComponents,
		meta: {
			title: `Master Perusahaan`,
			access_right: "",
		},
		children: [
			{
				path: "/companies/list",
				name: "companies-list",
				component: () => import("@/views/pages/admin/companies/ListCompanies.vue"),
				meta: {
					group: "companies",
					title: `Daftar Perusahaan`,
					access_right: "",
				},
			},
			{
				path: "/companies/add",
				name: "companies-add",
				component: () => import("@/views/pages/admin/companies/AddCompanies.vue"),
				meta: {
					title: `Tambah Perusahaan`,
					access_right: "",
				},
			},
			{
				path: "/companies/edit/:id",
				name: "companies-edit",
				component: () => import("@/views/pages/admin/companies/EditCompanies.vue"),
				meta: {
					title: `Ubah Perusahaan`,
					access_right: "",
				},
			},
			{
				path: "/companies/detail/:id",
				name: "companies-detail",
				component: () => import("@/views/pages/admin/companies/DetailCompanies.vue"),
				meta: {
					title: `Detail Perusahaan`,
					access_right: "",
				},
			},
		],
	},
	// alumnuses
	{
		path: "/alumnuses",
		component: LayoutComponents,
		meta: {
			title: `Master Alumni`,
			access_right: "",
		},
		children: [
			{
				path: "/alumnuses/list",
				name: "alumnuses-list",
				component: () => import("@/views/pages/admin/alumnuses/ListAlumnuses.vue"),
				meta: {
					group: "alumnuses",
					title: `Daftar Alumni`,
					access_right: "",
				},
			},
			{
				path: "/alumnuses/add",
				name: "alumnuses-add",
				component: () => import("@/views/pages/admin/alumnuses/AddAlumnuses.vue"),
				meta: {
					title: `Tambah Alumni`,
					access_right: "",
				},
			},
			{
				path: "/alumnuses/edit/:id",
				name: "alumnuses-edit",
				component: () => import("@/views/pages/admin/alumnuses/EditAlumnuses.vue"),
				meta: {
					title: `Ubah Alumni`,
					access_right: "",
				},
			},
			{
				path: "/alumnuses/detail/:id",
				name: "alumnuses-detail",
				component: () => import("@/views/pages/admin/alumnuses/DetailAlumnuses.vue"),
				meta: {
					title: `Detail Alumni`,
					access_right: "",
				},
			},
		],
	},
];
const router = new VueRouter({
	routes,
});

export default router;
