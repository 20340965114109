import jwtService from "@/services/jwt.service";

const authMiddleware = (to, from, next) => {
  if (to.name !== "login") {
    if (jwtService.isTokenValid()) {
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
};

export default authMiddleware;
