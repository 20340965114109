<template>
    <div>
        <div class="container-fluid">
            <div class="row def-bg-blue">
                <div class="col-md-12">
                    <b-navbar variant="faded">
                        <div class="container-fluid py-1">
                            <b-navbar-brand href="#">
                                <div class="d-flex align-items-center ">
                                    <img src="media/image/logo_sekolah.png" class="logo">
                                    <h4 class="mb-0 typo-def-navbar-title-dark ml-2">CONNECT-IN</h4>
                                </div>
                            </b-navbar-brand>
                            <b-navbar-nav>
                                <b-nav-text class="d-flex font-weight-bold text-white d-sm-none d-md-flex d-lg-flex ">
                                    <span class="d-sm-none d-flex d-md-flex d-lg-flex align-items-center mr-2 ml-1">Hi,
                                        {{
                                            user.name }}</span>
                                </b-nav-text>
                                <b-nav-item-dropdown right>
                                    <template #button-content>
                                        <div class="btn btn-primary">A</div>
                                    </template>
                                    <b-dropdown-item @click="signOut">Sign Out</b-dropdown-item>
                                </b-nav-item-dropdown>
                            </b-navbar-nav>
                        </div>
                    </b-navbar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import jwtService from '@/services/jwt.service';
import moment from 'moment';
import Swal from 'sweetalert2';


export default {
    name: 'NavbarAdminComponent',
    data() {
        return {
            user: {},
            greeting: "",
        };
    },
    methods: {
        getCurrentUser() {
            this.user = JSON.parse(localStorage.getItem("user"))
            if (this.user === null) {
                this.$router.push("login")
            }
        },
        getGreetings() {
            // Get the current time
            const currentTime = moment();

            // Get the current hour
            const currentHour = currentTime.hour();

            // Determine the time of day
            if (currentHour >= 0 && currentHour < 12) {
                this.greeting = 'Good Morning';
            } else if (currentHour >= 12 && currentHour < 18) {
                this.greeting = 'Good Afternoon';
            } else {
                this.greeting = 'Good Evening';
            }
        },

        signOut() {
            Swal.fire({
                title: 'Apakah Anda yakin?',
                text: "Anda akan keluar dari sesi ini.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#203d7f',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya, keluar!',
                cancelButtonText: 'Batal'
            }).then((result) => {
                if (result.isConfirmed) {
                    jwtService.destroyRefreshToken();
                    jwtService.destroyToken();
                    jwtService.destroyUser();

                    Swal.fire({
                        title: 'Logout Berhasil!',
                        text: 'Anda telah berhasil keluar.',
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    });

                    this.$router.push("/login");
                }
            });
        }
    },
    mounted() {

        this.getCurrentUser()
        this.getGreetings()
    },

};
</script>

<style scoped>
.logo {
    width: 35px;
    height: auto;
}

a.dropdown-item.disabled {
    color: black !important;
    background-color: gray;
}
</style>